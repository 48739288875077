<template>
  <div class="card card-custom gutter-b">
    <div class="card-header pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"> Testdaten erstellen</span>
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body pt-3 pb-0">
      <h5>Testreise mit zwei Terminen erstellen</h5>
      <div class="border mb-3 p-5">
        <b-input-group>
          <div class="col-6">
            <h2>Reisetermin 1</h2>
            <b-input-group-text>Tage bis Startdatum</b-input-group-text>
            <b-form-input class="mr-2" type="number" v-model="daysUntilStartdatum1"></b-form-input>
            <b-input-group-text>Tage bis Releasedatum</b-input-group-text>
            <b-form-input class="mr-2" type="number" v-model="daysUntilReleasedatum1"></b-form-input>
          </div>
          <div class="col-6">
            <h2>Reisetermin 2</h2>
            <b-input-group-text>Tage bis Startdatum</b-input-group-text>
            <b-form-input class="mr-2" type="number" v-model="daysUntilStartdatum2"></b-form-input>
            <b-input-group-text>Tage bis Releasedatum</b-input-group-text>
            <b-form-input class="mr-2" type="number" v-model="daysUntilReleasedatum2"></b-form-input>
          </div>
          <div class="mt-4 col-12">
            <b-input-group-text>Flüge hinzufügen?</b-input-group-text>
            <b-form-select class="" type="number" v-model="fluegeVorhanden">
              <b-form-select-option :value="true">Ja</b-form-select-option>
              <b-form-select-option :value="false">Nein</b-form-select-option>
            </b-form-select>
            <b-button block @click="createTestreise" variant="success">Testreise erstellen</b-button>
          </div>
        </b-input-group>
        <div class="my-2">
          <label for="tags-basic">Erstellte Reisenummern:</label>

          <div class="p-5 rounded bg-secondary">
            <LoadSpinnerWidget
              v-if="isCreatedReisenLoading"
              :dataAvailable="listCreatedReisen.length !== 0"
              :isLoading="isCreatedReisenLoading"
              class="py-5"
            ></LoadSpinnerWidget>

            <h5>
              <b-badge
                class="m-1"
                variant="primary"
                v-for="(item, index) in listCreatedReisen"
                :key="'testreise-pill-' + index"
              >
                Reisekürzel:{{ item.reisekuerzel }}</b-badge
              >
            </h5>
          </div>
        </div>
      </div>

      <h5>Reisetermin Test</h5>
      <div class="border mb-3 p-5">
        <div>
          <b-input-group>
            <template #prepend>
              <b-input-group-text>Reisetermin ID</b-input-group-text>
            </template>
            <b-form-input
              type="number"
              :state="reiseterminIdState"
              v-model="reiseterminIDInput"
            ></b-form-input>

            <template #append>
              <b-button :disabled="!reiseterminIdState" @click="reiseterminAbsagen()" variant="success"
                >Absagen</b-button
              >
            </template>
          </b-input-group>
          <LoadSpinnerWidget
            v-if="isReiseterminAbsagenLoading"
            class="p-5"
            :dataAvailable="true"
            :isLoading="isReiseterminAbsagenLoading"
          ></LoadSpinnerWidget>
        </div>
      </div>

      <h5>Vorgänge Test</h5>
      <div class="border mb-3 p-5">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>BNR</b-input-group-text>
          </template>
          <b-form-input type="number" :state="bnrState" v-model="vorgaengeBNRInput"></b-form-input>
          <template #append>
            <b-dropdown :disabled="!bnrState" text="Aktion ausführen" variant="success">
              <b-dropdown-item @click="vorgangAction(0)">Stornieren</b-dropdown-item>
              <b-dropdown-item @click="vorgangAction(1)">Neue Flugdaten</b-dropdown-item>
              <b-dropdown-item @click="vorgangAction(2)">Flugtickets Ausstellen</b-dropdown-item>
              <b-dropdown-item @click="vorgangAction(3)">Buchen</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-input-group>
        <LoadSpinnerWidget
          v-if="isVorgaengeActionsLoading"
          class="p-5"
          :dataAvailable="true"
          :isLoading="isVorgaengeActionsLoading"
        ></LoadSpinnerWidget>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { mapState } from 'vuex';
import apiService from '@/core/common/services/api.service';
import LoadSpinnerWidget from '@/components/common/load-spinner-widget.vue';

export default {
  name: 'einstellungen-test-data',
  data() {
    return {
      listCreatedReisen: [],
      isCreatedReisenLoading: false,
      isReiseterminAbsagenLoading: false,
      isVorgaengeActionsLoading: false,
      vorgaengeBNRInput: '',
      reiseterminIDInput: '',
      daysUntilStartdatum1: 40,
      daysUntilReleasedatum1: 5,
      daysUntilStartdatum2: 45,
      daysUntilReleasedatum2: 6,
      fluegeVorhanden: true,
    };
  },
  components: { LoadSpinnerWidget },
  methods: {
    createTestreise() {
      this.isCreatedReisenLoading = true;
      apiService
        .post('Reise/simulate/testdata', {
          daysUntilStartdatum1: this.daysUntilStartdatum1,
          daysUntilReleasedatum1: this.daysUntilReleasedatum1,
          daysUntilStartdatum2: this.daysUntilStartdatum2,
          daysUntilReleasedatum2: this.daysUntilReleasedatum2,
          fluegeVorhanden: this.fluegeVorhanden,
        })
        .then(({ data }) => {
          this.listCreatedReisen.push(data.result);
        })
        .catch(response => {
          console.error(response);
        })
        .finally(() => {
          this.isCreatedReisenLoading = false;
        });
    },
    reiseterminAbsagen() {
      this.isReiseterminAbsagenLoading = true;
      apiService
        .put(`Reisetermin/${this.reiseterminIDInput}/simulate/absage`, {
          id: this.reiseterminIDInput,
        })
        .then(() => {
          this.toast(`Reisetermin ${this.reiseterminIDInput} abgesagt!`);
        })
        .catch(response => {
          this.toast('Fehler bei der Absage!', 'danger');
          console.error(response);
        })
        .finally(() => {
          this.reiseterminIDInput = '';
          this.isReiseterminAbsagenLoading = false;
        });
    },
    vorgangAction(action) {
      this.isVorgaengeActionsLoading = true;
      apiService
        .put(`Vorgang/${this.vorgaengeBNRInput}/simulate/actions`, {
          id: this.vorgaengeBNRInput,
          simulateVorgangActionsEnum: action,
        })
        .then(() => {
          this.toast(`Aktion für Vorgang ${this.vorgaengeBNRInput} ausgeführt!`);
        })
        .catch(err => {
          this.toast('Fehler bei der Durchführung der Aktion!', 'danger');

          console.log(err);
        })
        .finally(() => {
          this.vorgaengeBNRInput = '';
          this.isVorgaengeActionsLoading = false;
        });
    },
  },
  computed: {
    ...mapState({
      roles: state => state.auth.account.idTokenClaims.roles,
    }),
    reiseterminIdState() {
      return this.reiseterminIDInput.length > 0 ? true : false;
    },
    bnrState() {
      return this.vorgaengeBNRInput.length > 0 ? true : false;
    },
  },
};
</script>
